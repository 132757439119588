import { render, staticRenderFns } from "./CustomFieldValue.vue?vue&type=template&id=a2c1bf52&scoped=true&"
import script from "./CustomFieldValue.vue?vue&type=script&setup=true&lang=js&"
export * from "./CustomFieldValue.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../frontend-admin/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2c1bf52",
  null
  
)

export default component.exports